import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { firestore } from "../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { Link } from "react-router-dom";
import "./SurveyListPage.css";
import ParentComponent from "./Geral/ParentComponent";
import Footer from "./Geral/Footer";

const SurveyCard = ({ titleEn, titlePt, id }) => {
  const { i18n } = useTranslation();
  const title = i18n.language === "en" ? titleEn : titlePt;

  // Create a URL-safe slug based on the title and the Firestore ID
  const createSlug = (title, id) => {
    const sanitizedTitle = title.toLowerCase().replace(/\s+/g, "-").replace(/[^\w-]+/g, "");
    return `${sanitizedTitle}-${id}`;
  };

  const slug = createSlug(title, id);

  return (
    <div className="survey-card">
      <Link to={`/Inquerito/${slug}`} className="survey-link">
        <h3>{title}</h3>
      </Link>
    </div>
  );
};

const SurveyListPage = () => {
  const { t } = useTranslation();
  const [surveys, setSurveys] = useState([]);

  useEffect(() => {
    const fetchSurveys = async () => {
      const surveysCollectionReference = collection(firestore, "surveys");
      const surveysSnapshot = await getDocs(surveysCollectionReference);
      const surveysList = surveysSnapshot.docs.map((document) => ({
        id: document.id,
        ...document.data(),
      }));
      setSurveys(surveysList);
    };

    fetchSurveys();
  }, []);

  return (
    <div>
      <ParentComponent />
      <div className="surveys-container">
        <h1 className="surveys-title">Inquéritos</h1>
        <div className="surveys-grid">
          {surveys.map((survey) => (
            <SurveyCard
              key={survey.id}
              id={survey.id}
              titleEn={survey.titleEn}
              titlePt={survey.titlePt}
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SurveyListPage;
