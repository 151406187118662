import React from "react";
import Footer from "./Geral/Footer";
import ParentComponent from "./Geral/ParentComponent";
import SocialMediaButtons from "./HomePage/SocialMediaButtons";
import { useTranslation } from "react-i18next";

const events = [
  {
    id: 1,
    startDate: "2025-02-01",
    endDate: "2025-02-02",
    titlePt: "Passeio de Inverno",
    titleEn: "Winter Tour",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2Fp%20inverno.jpg?alt=media&token=add06282-2af6-448f-8834-4486d6b0cf08",
  },
  {
    id: 2,
    startDate: "2025-03-04",
    endDate: "2025-03-04",
    titlePt: "Passeio de Carnaval",
    titleEn: "Carnival Tour",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2FP%20CARNAVAL.jpg?alt=media&token=86424ff6-b399-4ccc-a602-fb9e19ded848",
  },
  {
    id: 3,
    startDate: "2025-03-23",
    endDate: "2025-03-23",
    titlePt: "Passeio da Primavera",
    titleEn: "Spring Tour",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2FP%20PRIMAVERA.jpg?alt=media&token=b8fde133-0f72-4fcf-8df5-a8fdc2c74fad",
  },
  {
    id: 4,
    startDate: "2025-04-11",
    endDate: "2025-04-13",
    titlePt: "Norte Classic",
    titleEn: "Norte Classic",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2FNORTE%20CLASSIC.jpg?alt=media&token=8993e018-5a1f-42d7-8e6d-bafc58bd27c7",
  },
  {
    id: 5,
    startDate: "2025-05-10",
    endDate: "2025-05-10",
    titlePt: "Passeio Feminino",
    titleEn: "Women's Tour",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2FP%20FEMININO.jpg?alt=media&token=3bf3d147-c9da-4315-b558-b0028ff673b5",
  },
  {
    id: 6,
    startDate: "2025-05-31",
    endDate: "2025-05-31",
    titlePt: "Passeio Feminino Cascais",
    titleEn: "Women's Tour Cascais",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2FP%20FEMININO%20CASCAIS.jpg?alt=media&token=e5889851-0ad8-4b46-8e28-e41301b10ce0",
  },
  {
    id: 7,
    startDate: "2025-06-14",
    endDate: "2025-06-15",
    titlePt: "Passeio Jaguar/Daimler",
    titleEn: "Jaguar/Daimler Tour",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2FP%20JAGUAR.jpg?alt=media&token=83dacf0f-aeb2-45e2-8e92-d971b97c46f4",
  },
  {
    id: 8,
    startDate: "2025-06-28",
    endDate: "2025-06-28",
    titlePt: "Celebração dos 60 anos CPAA",
    titleEn: "CPAA 60th Anniversary Celebration",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2F60%20ANOS%20CPAA.jpg?alt=media&token=7074cc1e-ae32-453c-8993-641f64fc5444",
  },
  {
    id: 9,
    startDate: "2025-07-11",
    endDate: "2025-07-13",
    titlePt: "Algarve Classic Cars",
    titleEn: "Algarve Classic Cars",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2FALGARVE%20CLASSIC%20CARS.jpg?alt=media&token=9de71c3d-2a28-4127-ad0f-a23d949e85bb",
  },
  {
    id: 10,
    startDate: "2025-07-19",
    endDate: "2025-07-19",
    titlePt: "Encontro dos Jovens",
    titleEn: "Youth Meeting",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2FENCONTRO%20JOVENS.jpg?alt=media&token=69864ab9-67a9-4e1e-987f-b35130ec8458",
  },
  {
    id: 11,
    startDate: "2025-09-20",
    endDate: "2025-09-21",
    titlePt: "Clássicos à Galiza",
    titleEn: "Classics to Galicia",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2FCLASSICOS%20GALIZA.jpg?alt=media&token=a8603fc2-4bb3-4f76-b656-836b1b87b3a5",
  },
  {
    id: 12,
    startDate: "2025-10-10",
    endDate: "2025-10-12",
    titlePt: "48 Horas Alentejo",
    titleEn: "48 Hours Alentejo",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2F48%20H%20ALENTEJO.jpg?alt=media&token=726c964d-db74-4168-950d-4eb75147b0b3",
  },
  {
    id: 13,
    startDate: "2025-10-18",
    endDate: "2025-10-18",
    titlePt: "Passeio das Motas",
    titleEn: "Motorcycle Tour",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2FP%20MOTAS.jpg?alt=media&token=8d6d6889-7a35-4d23-bc1c-19297951250e",
  },
  {
    id: 14,
    startDate: "2025-11-08",
    endDate: "2025-11-08",
    titlePt: "Passeio de S. Martinho",
    titleEn: "S. Martinho Tour",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2FP%20S%20MARTINHO.jpg?alt=media&token=c264309c-8401-4153-9c1c-de5f96c21194",
  },
  {
    id: 15,
    startDate: "2025-11-15",
    endDate: "2025-11-15",
    titlePt: "Passeio de Outono",
    titleEn: "Autumn Tour",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2FP%20OUTONO.jpg?alt=media&token=030f7059-994e-4c47-8d83-9e606bc9928a",
  },
  {
    id: 16,
    startDate: "2025-12-13",
    endDate: "2025-12-13",
    titlePt: "Jantar de Natal Porto",
    titleEn: "Christmas Dinner Porto",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2FBP200114.jpg?alt=media&token=da99b7ee-fda7-4400-a360-19963bc79344",
  },
  {
    id: 17,
    startDate: "2025-12-13",
    endDate: "2025-12-13",
    titlePt: "Jantar de Natal Lisboa",
    titleEn: "Christmas Dinner Lisbon",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/Others%2FJ%20NATAL%20LISBOA.jpg?alt=media&token=8433758d-efb0-4c25-bab9-2614d5668bc6",
  },
];

const CPAA2025 = () => {
  const { t, i18n } = useTranslation();

  const formatEventDate = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const startDay = start.getDate();
    const endDay = end.getDate();
    const startMonth = start.toLocaleString(i18n.language, { month: "long" });
    const endMonth = end.toLocaleString(i18n.language, { month: "long" });

    if (startDate === endDate) {
      return (
        <>
          <div className="event-date-day">{startDay}</div>
          <div className="event-date-month">{startMonth}</div>
        </>
      );
    } else if (startMonth === endMonth) {
      return (
        <>
          <div className="event-date-day">{`${startDay}-${endDay}`}</div>
          <div className="event-date-month">{startMonth}</div>
        </>
      );
    } else {
      return (
        <>
          <div className="event-date-day">{startDay}</div>
          <div className="event-date-month">{startMonth}</div>
          <div className="event-date-day">{endDay}</div>
          <div className="event-date-month">{endMonth}</div>
        </>
      );
    }
  };

  const sortedEvents = events.sort(
    (a, b) => new Date(a.startDate) - new Date(b.startDate)
  );

  return (
    <div>
      <ParentComponent />
      <div className="wrapper">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/cpaa-ac0ff.appspot.com/o/articles%2F0d7f07be-620b-4e3c-87ce-72cf64284144?alt=media&token=9c9481c7-ae6b-46ed-85c2-9d0354d72f10"
          alt={t("CPAA2025.imageAlt")}
          className="main-article-image"
        />
        <h1 className="article-title">{t("CPAA2025.title")}</h1>
        <div className="content-block">
          <div>{t("CPAA2025.intro1")}</div>
        </div>
        <div className="content-block">
          <div>{t("CPAA2025.intro2")}</div>
        </div>
        <div className="content-block">
          <div>{t("CPAA2025.intro3")}</div>
        </div>
        <div className="events-list-container">
          {sortedEvents.map((event) => (
            <div key={event.id} className="event-card">
              <div className="event-image-container">
                <img
                  src={event.mainImage}
                  alt={event.titlePt}
                  className="event-image"
                />
                <div className="event-date-container">
                  {formatEventDate(event.startDate, event.endDate)}
                </div>
              </div>
              <div className="event-title">
                {i18n.language === "pt" ? event.titlePt : event.titleEn}
              </div>
            </div>
          ))}
        </div>
      </div>
      <SocialMediaButtons />
      <Footer />
    </div>
  );
};

export default CPAA2025;
